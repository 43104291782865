import { useCallback, useEffect, useState, RefObject } from 'react'

/**
 * Returns current width of specified element.
 *
 * @param {RefObject<HTMLElement>} ref element to use in width calculation
 * @returns {number} The current width of the element
 */
const useElementWidth = (ref: RefObject<HTMLElement>): number => {
  if (typeof window === 'undefined') return 0

  const getWidth = useCallback(() => {
    return (
      (ref?.current?.getBoundingClientRect()?.width ?? 0) *
        (window.visualViewport?.scale || 1) || 0
    )
  }, [ref])

  const [width, setWidth] = useState<number>(0)

  const elObserver = new ResizeObserver(entries => {
    window.requestAnimationFrame(() => {
      if (!ref?.current) return

      if (entries?.[0]?.contentBoxSize?.[0]) {
        setWidth(entries[0].contentBoxSize[0].inlineSize)
      } else if (entries?.[0]?.contentBoxSize) {
        setWidth(
          (entries[0].contentBoxSize as ResizeObserverSize[])[0]?.inlineSize ||
            0
        )
      } else {
        setWidth(entries[0].contentRect.width)
      }
    })
  })

  useEffect(() => {
    setWidth(getWidth())
  }, [getWidth])

  useEffect(() => {
    if (!ref?.current) return

    elObserver.observe(ref.current)

    return () => {
      if (!ref?.current) return
      elObserver.unobserve(ref.current)
    }
  }, [ref, elObserver])

  return width
}

export { useElementWidth }
