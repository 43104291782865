import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useInterval } from '@hooks/common/useInterval'
import { formatBadgeCountdown } from '@lib/promotion/utils'
import { instanceToPlain } from 'class-transformer'

import type { CoverBadgeProps, WithCoverBadgeProps } from './interface'

export function withCoverBadge(Component: React.FC<CoverBadgeProps>) {
  function WithCoverBadge({
    bookBadges,
    buyAllChaptersPromotion,
    readFreePromotion,
    ...props
  }: WithCoverBadgeProps) {
    const { tick } = useInterval()

    const discountCountdown = useMemo(() => {
      const now = DateTime.now().toUTC()
      const diff = DateTime.fromISO(buyAllChaptersPromotion?.endDate || '')
        .toUTC()
        .diff(now, ['days', 'hours', 'minutes', 'seconds'])
      const { days, hours, minutes, seconds } = diff.toObject()
      const countDownFormat = formatBadgeCountdown({
        days,
        hours,
        minutes,
        seconds,
      })

      if (!!countDownFormat) {
        return diff.toFormat(countDownFormat)
      }

      return ''
    }, [tick, buyAllChaptersPromotion])

    const readFreeCountdown = useMemo(() => {
      const now = DateTime.now().toUTC()
      const diff = DateTime.fromISO(readFreePromotion?.endedAt || '')
        .toUTC()
        .diff(now, ['days', 'hours', 'minutes', 'seconds'])
      const { days, hours, minutes, seconds } = diff.toObject()
      const countDownFormat = formatBadgeCountdown({
        days,
        hours,
        minutes,
        seconds,
      })

      if (!!countDownFormat) {
        return diff.toFormat(countDownFormat)
      }

      return ''
    }, [tick, readFreePromotion])

    const componentProps = {
      ...props,
      discountCountdown,
      readFreeCountdown,
      discountPercentage: buyAllChaptersPromotion?.discountPercentage,
      badgeImages: bookBadges?.map(
        badge => instanceToPlain(badge).badgeImageUrl
      ),
    }

    return <Component {...componentProps} />
  }

  return WithCoverBadge
}
