import React, { forwardRef } from 'react'
import Link from 'next/link'
import cx from 'classnames'
import Image from 'next/image'

import { abbreviateNumber } from '@lib/utils'
import { BookEnum } from '@interfaces/BookEnum'
import { TagType } from '@models/story/TagType'
import { BookOutlineIcon } from '@components/icons/BookOutlineIcon'
import { CartoonIcon } from '@components/icons/CartoonIcon'
import { ListIcon } from '@components/icons/ListIcon'
import { ChatboxIcon } from '@components/icons/ChatboxIcon'
import { DeleteBinIcon } from '@components/icons/DeleteBinIcon'
import { EBookIcon } from '@components/icons/EBookIcon'
import { EyeIcon } from '@components/icons/EyeIcon'
import { HeartIcon } from '@components/icons/HeartIcon'
import { HeartSolidIcon } from '@components/icons/HeartSolidIcon'
import { PenIcon } from '@components/icons/PenIcon'
import { CoverBadge } from '@features/story/components/CoverBadge'
import { StoryCardProps } from './interface'
import { BookStatus } from './components/BookStatus'
import { MobileTabletFooter } from './components/MobileTabletFooter'

export const StoryCard = forwardRef<HTMLDivElement, StoryCardProps>(
  (
    {
      id,
      title,
      imageUrl,
      penName,
      content,
      category,
      chapter,
      view,
      chat,
      chapterPurchasedCount,
      isLiked,
      like,
      isEnded,
      tags,
      direction,
      chapterName,
      bookType,
      status,
      slug,
      dataTest,
      categoryPath,
      isShowBin = false,
      isShowStatus,
      isToggleBookLike,
      isPurchased,
      isShowChapterName,
      isDeleteLoading,
      isReplace,
      handleLikeBook,
      handleGoToSearchTagPage,
      showCategoryDialog,
      handleClickStoryCard,
      createUserTagClick,
      onDelete,
      isShowBookPromotion,
      buyAllChaptersPromotion,
      readFreePromotion,
      bookBadges,
      width,
    },
    ref
  ) => {
    function tranformBookType() {
      switch (bookType) {
        case BookEnum.NOVEL:
          return {
            label: 'นิยาย',
            icon: (
              <BookOutlineIcon className='mr-[5px]' width='18' height='18' />
            ),
          }
        case BookEnum.MANGA:
          return {
            label: 'การ์ตูน',
            icon: <CartoonIcon className='mr-[5px]' width='18' height='18' />,
          }
        default:
          return {
            label: 'อีบุ๊ก',
            icon: <EBookIcon className='mr-[5px]' width='18' height='18' />,
          }
      }
    }

    return (
      <div
        className={cx(
          'w-full flex p-[14px] rounded-[8px] border border-gray-4 dark:border-purple shadow-card tablet-mobile:p-[10px] tablet-mobile:grid cursor-pointer',
          {
            'flex-col w-full': direction === 'vertical',
            'desktop:space-x-[20px]': direction === 'horizontal',
          }
        )}
        onClick={handleClickStoryCard}
        data-test={`story-card-${title}`}
      >
        <Link href={`/story/${id}`} prefetch={false} legacyBehavior>
          <a
            className={cx('overflow-hidden rounded-[8px] shrink-0', {
              'mb-[10px] tablet-mobile:mb-[8px]': direction === 'vertical',
              'desktop:max-w-[140px] w-full tablet-mobile:mb-[8px]':
                direction === 'horizontal',
            })}
          >
            <div
              ref={ref}
              className='pt-[140.625%] bg-gray-3 dark:bg-dark-9 relative w-full h-full'
              style={{
                fontSize: `${Math.max(
                  Math.round(
                    0.062 * width - (direction === 'vertical' ? 4.24 : 2.24)
                  ),
                  7
                )}px`,
              }}
              onClick={event => {
                event.preventDefault()
              }}
            >
              {imageUrl ? (
                <Image
                  src={imageUrl}
                  layout='fill'
                  alt={title}
                  sizes='(max-width: 768px) 100vw, 
               (max-width: 1200px) 50vw, 
               33vw'
                  quality={20}
                />
              ) : null}
              <CoverBadge
                hasSalePromotions={isShowBookPromotion}
                buyAllChaptersPromotion={buyAllChaptersPromotion}
                readFreePromotion={readFreePromotion}
                bookBadges={bookBadges}
              />
            </div>
          </a>
        </Link>
        <section data-test={`story-card-count-${dataTest}`} className='flex-1'>
          <section
            className={cx('flex justify-between', {
              'mb-[6px] tablet-mobile:mb-[4px]': direction === 'horizontal',
              'mb-[10px] tablet-mobile:mb-[4px]': direction === 'vertical',
            })}
          >
            <Link href={`/story/${id}`} prefetch={false} legacyBehavior>
              <a>
                <div
                  data-test={title}
                  className='font-mitr text-[18px] tablet-mobile:text-[14px] text-secondary dark:text-dark-10 font-medium line-clamp-1 break-word'
                  onClick={event => event.preventDefault()}
                >
                  {title}
                </div>
              </a>
            </Link>
            {isEnded && direction === 'horizontal' && (
              <span className='whitespace-nowrap text-[12px] tablet-mobile:hidden text-white font-bold px-[10px] py-[4px] bg-primary rounded-[4px] ml-[20px]'>
                จบแล้ว
              </span>
            )}
          </section>
          <section
            className={cx(
              'flex justify-between font-light text-[14px] tablet-mobile:text-[12px] text-secondary dark:text-dark-10',
              {
                'flex-col tablet-mobile:flex-row h-[42px] mb-[4px]':
                  direction === 'vertical',
                'mb-[15px] tablet-mobile:mb-[10px]':
                  !isShowChapterName && direction === 'horizontal',
                'mb-[6px]': isShowChapterName && direction === 'horizontal',
              }
            )}
          >
            <div
              className='grid grid-cols-[fit-content(70px)_1fr] gap-x-[5px] pr-[10px] mr-[10px]'
              onClick={e => e.stopPropagation()}
            >
              <div className='text-secondary-1'>
                <PenIcon className='w-[18px] h-[18px]' />
              </div>
              <Link
                href={`/profile/${slug}?tab=penName&text=${penName}`}
                legacyBehavior
                prefetch={false}
              >
                <a className='hover:underline break-all truncate dark:text-dark-10'>
                  {penName}
                </a>
              </Link>
            </div>

            {isEnded && (
              <span className='whitespace-nowrap text-[12px] desktop:hidden text-white font-bold px-[4px] bg-primary rounded-[4px] h-fit'>
                จบ
              </span>
            )}
          </section>
          {isShowChapterName && (
            <p className='text-primary text-[12px] font-medium mb-[4px] text-left h-[24px] line-clamp-1 break-word'>
              {chapterName ? `ตอนใหม่: ${chapterName}` : ''}
            </p>
          )}
          <p
            className={cx(
              'font-light text-[14px] text-secondary dark:text-dark-10 tablet-mobile:hidden line-clamp-2 break-word leading-[1.75] h-[48px]',
              {
                'mb-[15px]': !isShowChapterName && direction === 'horizontal',
                'mb-[5px]': isShowChapterName && direction === 'horizontal',
                'mb-[8px]': direction === 'vertical',
              }
            )}
          >
            {content}
          </p>

          <section
            className={cx('flex items-center text-[12px] font-light', {
              'flex-wrap mb-[15px] tablet-mobile:mb-[10px] desktop-tablet:h-[50px]':
                direction === 'vertical',
              'mb-[10px] desktop:space-x-[10px]': direction === 'horizontal',
            })}
          >
            <div
              className={cx(
                'flex items-center tablet-mobile:items-start tablet-mobile:flex-col tablet-mobile:w-full',
                {
                  'w-full mb-[10px] tablet-mobile:mb-0 tablet-mobile:flex-col':
                    direction === 'vertical',
                  'desktop:h-[24px]': direction === 'horizontal',
                }
              )}
            >
              <div className='flex items-center text-secondary dark:text-dark-10 pr-[10px] mr-[10px] border-r border-gray-4 dark:border-purple tablet-mobile:border-0'>
                {tranformBookType().icon}
                {tranformBookType().label}
              </div>
              <div className='flex justify-between items-center w-full'>
                <div
                  onClick={event => {
                    event.stopPropagation()
                  }}
                >
                  <Link href={categoryPath} prefetch={false}>
                    <a
                      className={cx(
                        'text-primary underline line-clamp-1 break-word',
                        {
                          'max-w-[80px]': isShowStatus && !!status,
                        }
                      )}
                    >
                      {category}
                    </a>
                  </Link>
                </div>
                {isShowStatus && status && (
                  <BookStatus className='desktop:hidden' value={status} />
                )}
              </div>
            </div>
            <div className='h-[22px] flex space-x-[10px]'>
              {tags.slice(0, 2).map((tag: TagType, index: number) => (
                <div
                  key={index}
                  className='px-[10px] py-[2px] text-secondary-1 dark:text-dark-10 bg-gray-4 dark:bg-dark-13 tablet-mobile:hidden rounded-[4px] max-w-[100px] truncate relative'
                  onClick={event => {
                    event.stopPropagation()
                    createUserTagClick(tag.id)
                  }}
                >
                  <Link
                    href={handleGoToSearchTagPage(tag.name)}
                    replace={isReplace}
                    prefetch={false}
                  >
                    <a>{tag.name}</a>
                  </Link>
                </div>
              ))}
              {tags.length > 2 ? (
                <button
                  className='px-[5px] py-[2px] border border-gray-4 dark:border-purple rounded-[4px] tablet-mobile:hidden text-secondary-1 min-w-fit font-light'
                  type='button'
                  onClick={event => {
                    event.stopPropagation()
                    showCategoryDialog()
                  }}
                >
                  + {abbreviateNumber(tags.slice(2).length, 0)}
                </button>
              ) : null}
            </div>
          </section>

          <MobileTabletFooter
            id={id}
            view={view}
            chapter={chapter}
            like={like}
            chapterPurchasedCount={chapterPurchasedCount}
            direction={direction}
            isLiked={isLiked}
            isToggleBookLike={isToggleBookLike}
            isPurchased={isPurchased}
            handleLikeBook={handleLikeBook}
          />

          <section className='flex justify-between items-center text-[12px] font-light text-secondary dark:text-primary tablet-mobile:hidden'>
            <div
              className={cx('flex space-x-[10px]', {
                'space-x-[20px]': direction === 'vertical',
              })}
            >
              <div
                className={cx('flex items-center', {
                  'flex-col': direction === 'vertical',
                })}
              >
                <ListIcon
                  className={cx({
                    'mr-[5px] dark:text-primary': direction === 'horizontal',
                  })}
                  width='18'
                  height='18'
                />
                <span className='text-secondary-1 dark:text-dark-10'>
                  {abbreviateNumber(chapter)}
                </span>
              </div>
              <div
                className={cx('flex items-center', {
                  'flex-col': direction === 'vertical',
                })}
              >
                <EyeIcon
                  className={cx({
                    'mr-[5px] dark:text-primary': direction === 'horizontal',
                  })}
                  width='18'
                  height='18'
                />
                <span className='text-secondary-1 dark:text-dark-10'>
                  {abbreviateNumber(view)}
                </span>
              </div>
              <div
                className={cx('flex items-center', {
                  'flex-col': direction === 'vertical',
                })}
              >
                <ChatboxIcon
                  className={cx({
                    'mr-[5px] dark:text-primary': direction === 'horizontal',
                  })}
                  width='18'
                  height='18'
                />
                <span className='text-secondary-1 dark:text-dark-10'>
                  {abbreviateNumber(chat)}
                </span>
              </div>
            </div>
            <div className='flex items-center space-x-[10px]'>
              {isShowBin && !isPurchased && (
                <button
                  data-test={`delete-button-${title}`}
                  type='button'
                  onClick={event => {
                    event.stopPropagation()
                    if (onDelete) {
                      onDelete(id)
                    }
                  }}
                  className='border border-gray-4 dark:border-purple rounded-[4px] p-[4px] text-secondary-1 dark:text-dark-10'
                  disabled={isDeleteLoading}
                >
                  <DeleteBinIcon className='w-[20px] h-[20px]' />
                </button>
              )}
              {/* {isShowNoti && (
              <button className='border border-gray rounded-[4px] p-[5px] text-gray-10'>
                <BellIcon className='w-[20px] h-[20px]' />
              </button>
            )} */}
              {isShowStatus && status && <BookStatus value={status} />}
              <button
                className={cx(
                  'px-[10px] py-[4px] border border-gray-4 dark:border-purple rounded-[8px] flex items-center',
                  {
                    'text-secondary dark:text-dark-10': isLiked,
                    'text-secondary-1': !isLiked,
                    'flex-col': direction === 'vertical',
                    hidden: isPurchased,
                  }
                )}
                type='button'
                disabled={isToggleBookLike || !!handleLikeBook}
                onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
                  if (handleLikeBook) {
                    event.stopPropagation()
                    await handleLikeBook(id)
                  }
                }}
              >
                <HeartIcon
                  className={cx('text-secondary dark:text-primary', {
                    'mr-[5px]': direction === 'horizontal',
                    'flex-col': direction === 'vertical',
                    hidden: isLiked,
                  })}
                  width='20'
                  height='20'
                />
                <HeartSolidIcon
                  className={cx('text-red', {
                    'mr-[5px]': direction === 'horizontal',
                    'flex-col': direction === 'vertical',
                    hidden: !isLiked,
                  })}
                  width='20'
                  height='20'
                />
                <span className='text-secondary-1'>
                  {abbreviateNumber(like)}
                </span>
              </button>
              {isPurchased && (
                <div className='bg-blue-2 dark:bg-purple px-[10px] py-[6px] rounded-[4px] text-[12px] dark:text-white-2 font-right text-primary'>
                  <span>ซื้อแล้ว &nbsp;</span>
                  <span>
                    {abbreviateNumber(chapterPurchasedCount || 0)} ตอน
                  </span>
                </div>
              )}
            </div>
          </section>
        </section>
      </div>
    )
  }
)

StoryCard.displayName = 'StoryCard' // Add a display name for debugging purposes
