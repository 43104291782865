// import Image from 'next/image'
import cn from 'classnames'

import type { CoverBadgeProps } from './interface'

export function CoverBadge({
  // hasSalePromotions,
  readFreeCountdown,
  discountCountdown,
  // discountPercentage,
  badgeImages,
}: CoverBadgeProps) {
  return (
    <>
      {/* {hasSalePromotions && (
        <div className='absolute w-[30%] h-[7%] top-[4.5%] left-0'>
          <Image src='/images/badge/pay-back.png' layout='fill' />
        </div>
      )} */}
      {/* {readFreeCountdown && (
        <div className='absolute w-[19.5%] h-[10%] top-[13%] left-0'>
          <Image src='/images/badge/read-free-badge.webp' layout='fill' />
        </div>
      )} */}
      {/* {discountPercentage && (
        <div className='absolute left-0 top-[13%] w-[20%] rounded-r-[8px] text-[1.8em] font-mitr font-medium text-white'>
          <div className='relative'>
            <div className='absolute inset-0 w-full h-full m-auto flex items-center justify-center'>
              <div className='h-fit leading-[1em]'>
                -{discountPercentage}
                <span className='text-[.6em]'>%</span>
              </div>
            </div>
            <img src='/images/badge/discount-bg.webp' />
          </div>
        </div>
      )} */}
      {(readFreeCountdown || discountCountdown) && (
        <div
          className={cn(
            'absolute bottom-0 left-0 right-0 w-full h-[10%] flex items-center justify-center text-[1.5em] text-white font-mitr font-medium rounded-b-[8px]',
            {
              'bg-[linear-gradient(to_right,#ec3b3b,#d92121)]':
                discountCountdown,
              'bg-[linear-gradient(to_right,#4A5CF0,#78C2FF)]':
                readFreeCountdown,
            }
          )}
        >
          {readFreeCountdown || discountCountdown}
        </div>
      )}
      {badgeImages?.length && (
        <ul className='absolute top-0 right-[5%] w-[17%] flex flex-col items-center'>
          {badgeImages.map((badge, index) => (
            <li
              key={badge + index}
              className={cn({ '-mt-[10%]': index > 0 })}
              style={{ zIndex: badgeImages.length - index }}
            >
              <img src={badge} style={{ width: '100%', height: 'auto' }} />
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
